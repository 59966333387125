import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  stroke: "currentColor",
  viewBox: "0 0 59 50"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M16.177 49.535c-3.73-.047-6.958-2.813-7.416-6.347-.5-3.845 1.708-7.406 5.39-8.392 1.623-.437 2.368-1.316 2.423-2.93.042-.976.263-1.947.263-2.92a8.95 8.95 0 0 1 1.634-5.108c3.753-5.564 7.542-11.1 11.256-16.687 2.178-3.284 5.47-5.124 8.75-6.995.326-.184.852 0 1.286 0-.04.526.08 1.124-.142 1.553-2.076 4.063-4.21 8.097-6.331 12.137-3.548 6.76-7.069 13.536-10.674 20.263-.771 1.434-.821 2.566.116 3.973 1.634 2.458 1.64 5.132.16 7.677s-3.75 3.805-6.715 3.776Zm.131-2.631a4.888 4.888 0 1 0 0-9.772c-2.681.032-4.945 2.28-4.929 4.893a4.98 4.98 0 0 0 4.929 4.879Zm5.047-16.737a2.51 2.51 0 0 0 2.46-2.385c.038-1.24-1.107-2.392-2.41-2.431a2.408 2.408 0 0 0-.05 4.816Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "m27.471 30.232 5.213 13.22c.422-.873.735-1.426.96-2.012.45-1.174 1.246-1.387 2.35-.98 2.454.906 2.48.911 3.598-1.315.527-1.077 1.3-1.392 2.398-.948q.663.225 1.279.558c.92.582 1.365.242 1.779-.67 1.292-2.861 1.315-2.848 4.19-1.643.336.142.683.263 1.168.442.368-.77.734-1.505 1.07-2.252.598-1.316 1.19-1.58 2.511-1.069.558.221 1.1.471 1.963.84-.402-1.153-.687-2.05-1.029-2.93q-4.539-11.647-9.105-23.28c-.668-1.711-.655-1.714-2.368-1.04a47 47 0 0 1-4.624 1.608c.59-1.471.818-3.137 2.737-3.6.715-.174 1.39-.527 2.09-.79 1.944-.716 3.333-.105 4.11 1.866q3.64 9.21 7.244 18.455c1.14 2.895 2.266 5.81 3.421 8.708.432 1.087.74 2.163-.202 3.124-.942.96-2.18.82-3.234.25-1.14-.619-1.616-.298-2.064.808-.815 2.018-1.894 2.465-3.836 1.497-1.214-.603-1.677-.263-2.158.868-.827 1.932-1.919 2.369-3.766 1.498-1.142-.545-1.692-.434-2.179.81-.79 2.024-1.842 2.416-3.86 1.466-1.119-.526-1.645-.387-2.053.808-.374 1.087-1.04 1.976-2.418 1.958-1.38-.019-1.911-.921-2.337-2.032-1.282-3.344-2.498-6.723-3.948-10-.763-1.731.142-2.75 1.1-4.223Zm-10.126-8.714-2.705 5.726c-1.303-1.476-2.632-.745-4.109-.121C6.534 28.826 1.74 26.541.5 22.444c-1.297-4.287 1.263-8.755 5.555-9.702 4.34-.958 8.58 1.868 9.158 6.192.184 1.376.819 2.226 2.132 2.584Zm-9.527-6.274a4.882 4.882 0 1 0 4.824 4.932 4.845 4.845 0 0 0-4.824-4.932Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "m44.595 15.352.71 1.842c-2.405.95-4.723 1.87-7.044 2.784-.995.39-2.013.724-2.987 1.158-.629.279-1.037.263-1.332-.427-.294-.689-.263-1.155.487-1.447 3.334-1.271 6.666-2.56 10.166-3.91Zm-5.705-3.225q.14-.142.313-.237c1.113-.442 2.231-.874 3.334-1.3 1.153 1.579 1.11 1.734-.603 2.416-.342.14-.69.263-1.034.402-1.663.658-1.795.571-2.01-1.281Zm-15.548.432-3.381 4.947c-1.295-2.558-.419-3.866 3.381-4.947Z"
    }, null, -1)
  ])))
}
export default { render: render }